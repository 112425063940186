<template>
	<div>
		<b-form-group :label="$t('country')">
			<country-selectbox v-model="country_id"></country-selectbox>
		</b-form-group>
		<b-form-group :label="$t('university')">
			<multiselect
				v-model="selected"
				:class="validateError != '' ? 'box-border-color' : ''"
				:clear-on-select="!multiple"
				:close-on-select="!multiple"
				:deselect-label="''"
				:multiple="multiple"
				:options="options"
				:placeholder="$t('select')"
				:select-label="''"
				:selected-label="''"
				label="text"
				track-by="value"
				@input="handleInput($event, multiple)"
			>
				<template v-if="multiple"
				          slot="selection"
				          slot-scope="{ values, search, isOpen}"
				>
                <span v-if="values.length && !isOpen" class="multiselect__single">
	                {{ translateNSelected(values) }}
                </span>
				</template>
				<span slot="noOptions">
				{{ $t("no_options") }}
            </span>
				<span slot="noResult">
                {{ $t("no_result") }}
            </span>
			</multiselect>
			<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
		</b-form-group>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	//  Component
	import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";

	// Service
	import UniversityService from "@/services/UniversityService";

	// Other
	import qs from "qs";

	export default {
		props: {
			country: {
				type: Number,
				default: null
			},
			value: {
				default: null
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		components: {
			CountrySelectbox
		},
		data() {
			return {
				country_id: null,
				options: [],
				selected: null,
			}
		},
		watch: {
			country: function (newValue) {
				this.country_id = newValue
			},
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple)
			},
			country_id: function (newValue, oldValue) {
				if (newValue != oldValue) {
					this.getOptions(newValue)
				}
			}
		},
		created() {
			this.country_id = this.country
		},
		methods: {
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions(countryId = null) {
				this.options = [];
				if (countryId == null) {
					return this.$emit("input", null)
				}
				let config = {
					params: {
						filter: {
							country_id: countryId
						},
						sort: 'name',
						page: 1,
						limit: 10000
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}
				UniversityService.getAll(config)
				                 .then((response) => {
					                 const data = response.data.data;
					                 if (data.length) {
						                 data.map(item => {
							                 this.options.push({
								                 value: item.id,
								                 text: item.name
							                 })
						                 })
					                 }
				                 })
				                 .then(() => {
					                 this.selected = this.setSelected(this.value, this.options, this.multiple)
					                 if (this.selected == null || this.selected.length == 0) {
						                 this.$emit("input", null)
					                 }
				                 })
			}
		}
	}
</script>
